import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  colors, Title4Top, Input, Button, ErrorText, SuccessText, mobileThresholdPixels,
}
  from '../styledComponents';
import routes from '../../Routes';

const Content = styled.div`
  margin: 50px auto;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 10px 0px;
  }
`;

const ResetPasswordBlock = ({ status, submit, inputChange }) => (
  <Content>
    {(status === 'ready' || status === 'success')
      && (
        <div>
          <Title4Top>Changer de mot de passe</Title4Top>
          {(status === 'success')
            ? (
              <div>
                <SuccessText>Mot de passe changé</SuccessText>
                <Link to="/">revenir à l‘accueil</Link>
              </div>
            )
            : (
              <div>
                <Input
                  type="password"
                  placeholder="Votre nouveau mot de passe"
                  onChange={(e) => inputChange('password', e.target.value)}
                />
                {(status === 'loading')
                  ? <p>chargement</p>
                  : (
                    <div>
                      <Button type="button" onClick={() => submit()}>
                        Envoyer
                      </Button>
                    </div>
                  )}
              </div>
            )}
        </div>
      )}
    {(status === 'loading') && <SuccessText>Chargement...</SuccessText>}
    {(status === 'error')
      && (
        <div>
          <Title4Top>Changer de mot de passe</Title4Top>
          <ErrorText>Oups, quelque chose s‘est mal passé</ErrorText>
          <SuccessText>
            {'Peut-être que le lien est expiré, '}
            <Link to={routes.ForgottenPasswordRequest.url}>
              cliquez ici pour refaire une demande de mot de passe oublié
            </Link>
          </SuccessText>
          <Link to="/">revenir à l‘accueil</Link>
        </div>
      )}
  </Content>
);

ResetPasswordBlock.propTypes = {
  status: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
};

export default ResetPasswordBlock;
